// 课程师资
<template>
  <div class="course">
    <TabPageLayout activeCode="yizhongkecheng">
      <div class="container schoolCourse">
        <div
          class="div_mod div_mod1"
          v-for="(block, index) in blocks"
          :key="block.id"
        >
          <div class="div_tit">
            <h2>{{ block.title }}</h2>
          </div>
          <div class="teach-list">
            <div
              class="teach-card"
              v-for="teach in blockArticles[index]"
              :key="teach.id"
              @click="
                $router.push({
                  path: '/portalDetails',
                  query: {
                    id: teach.id,
                    activeCode: 'yizhongkecheng',
                  },
                })
              "
            >
              <img :src="teach.files" alt="" />
              <h3 class="title">{{ teach.title }}</h3>
            </div>
          </div>
        </div>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabPageLayout from './components/TabPageLayout.vue'
import { useGetCategories } from './utils/useGetCategories'
import portalApi from '../../../lvya-common/src/api/eos/portal'

export default {
  components: {
    TabPageLayout,
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    typeId() {
      return (
        this.nav?.find((item) => item.code === 'yizhongkecheng')?.stypes[2]
          ?.id || undefined
      )
    },
  },
  data() {
    return {
      blocks: [],
      blockArticles: [],
    }
  },
  methods: {
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      console.log({ categories })
      this.blocks = categories.list
    },
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      const articles = []
      for (const block of this.blocks) {
        console.log(block, 'block')
        let reqData = {
          noSchoolId: true,
          schoolId: '7',
          pageNum: 1,
          pageSize: 999,
          typeId: block.id,
          // isContent: 'Y',
          applyState: 'Y',
        }
        let resData = await portalApi.getArtsByPage(reqData)
        console.log(resData, 'articles')
        articles.push(resData?.list)
        console.log(articles, 'articles')
      }
      this.blockArticles = articles
    },
  },
  async mounted() {
    await this.getBlocks()
    await this.getArticles()
  },
}
</script>

<style lang="less" scoped>
@import "./style/course.css";
@import "./style/common.css";
@import "./style/global.css";

.teach-list {
  display: flex;
  flex-wrap: wrap;
  .teach-card {
    width: calc(25% - 17px);
    margin-left: 17px;
    cursor: pointer;
    &:nth-child(4n-3) {
      margin-left: 0;
    }
  }
  .title {
    font-size: 0.25rem;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 850px) {
  .teach-list {
    .teach-card {
      width: calc(50% - 17px);
      // margin-left: 17px;
      &:nth-child(2n-1) {
        margin-left: 0;
      }
    }
  }
}
</style>
