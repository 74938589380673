var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course"},[_c('TabPageLayout',{attrs:{"activeCode":"yizhongkecheng"}},[_c('div',{staticClass:"container schoolCourse"},_vm._l((_vm.blocks),function(block,index){return _c('div',{key:block.id,staticClass:"div_mod div_mod1"},[_c('div',{staticClass:"div_tit"},[_c('h2',[_vm._v(_vm._s(block.title))])]),_c('div',{staticClass:"teach-list"},_vm._l((_vm.blockArticles[index]),function(teach){return _c('div',{key:teach.id,staticClass:"teach-card",on:{"click":function($event){return _vm.$router.push({
                path: '/portalDetails',
                query: {
                  id: teach.id,
                  activeCode: 'yizhongkecheng',
                },
              })}}},[_c('img',{attrs:{"src":teach.files,"alt":""}}),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(teach.title))])])}),0)])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }